import { render, staticRenderFns } from "./RightContainer.vue?vue&type=template&id=644a01b8&scoped=true"
import script from "./RightContainer.vue?vue&type=script&lang=js"
export * from "./RightContainer.vue?vue&type=script&lang=js"
import style0 from "./RightContainer.vue?vue&type=style&index=0&id=644a01b8&prod&lang=scss&scoped=true"
import style1 from "./RightContainer.vue?vue&type=style&index=1&id=644a01b8&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "644a01b8",
  null
  
)

export default component.exports