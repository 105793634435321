<template>
  <div class="detail__rightsection">
    <div class="dr__batchcard" v-if="isAuthenticated">
      <div class="dr--top">
        <div class="dr--top__left">
          <h5 class="dr__batchcard__title">
            {{ $t("my_achievements.download_or_share.title") }}
          </h5>
          <h6 class="dr__batchcard__content">
            {{ $t("my_achievements.download_or_share.sub_title") }}
          </h6>
        </div>
      </div>
      <div class="dr--bottom">
        <div
          :class="[
            'dr__batchcard--div',
            {
              disabled: isDisabled(o)
            }
          ]"
          v-for="(o, i) in options"
          :key="i"
          @click="onClick(o)"
        >
          <div
            class="dr__batchcard--div__sec"
            v-if="!o.isLoading"
            :id="`dr-batchcard-${o.id}`"
          >
            <img
              :src="isDisabled(o) ? o.disabledImgPath : o.imgPath"
              :alt="o.title"
            />
            <span>{{ o.title }}</span>
          </div>
          <div class="dr__batchcard--div__sec" v-else>
            <div v-if="o.id === 'share'">
              <img
                src="@/assets/images/achievements/detail/share-copied.svg"
                alt="check"
                width="20"
              />
              <span class="dr__batchcard--div__sec--copied">{{
                $t("my_achievements.download_or_share.copied")
              }}</span>
            </div>
            <div v-if="o.id === 'download' && o.isLoading">
              <LxpLoader />
              <span>{{ o.title }}</span>
            </div>
          </div>
        </div>
        <b-tooltip
          target="dr-batchcard-download"
          triggers="hover focus"
          placement="bottom"
          v-if="isDisabled(options[0])"
        >
          <div class="cd__tooltip--inner">
            <p class="mb-0">
              The PDF is being generated and will be ready shortly. You will be
              notified via email.
            </p>
          </div>
        </b-tooltip>
      </div>
    </div>
    <ContinueLearning v-if="showContinueLearning" :courses="courseList" />
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import { LxpLoader } from "didactica";
// mixins
import downloadCopy from "./mixins/downloadCopy";
import ContinueLearning from "./ContinueLearning.vue";
export default {
  components: { LxpLoader, ContinueLearning },
  mixins: [downloadCopy],
  data() {
    return {
      options: [
        {
          title: this.$t("general.download"),
          imgPath: require("@/assets/images/achievements/detail/download.svg"),
          disabledImgPath: require("@/assets/images/achievements/detail/download-disabled.svg"),
          id: "download",
          isLoading: false
        },
        {
          title: this.$t("course.share"),
          imgPath: require("@/assets/images/achievements/detail/share.svg"),
          id: "share",
          isLoading: false
        }
      ],
      courseList: []
    };
  },
  computed: {
    ...mapGetters(["enrolledCourses", "awardDetail", "language"]),
    showContinueLearning() {
      return this.isAuthenticated
        ? this.enrolledCourses?.results?.length > 0
        : this.courseList.length > 0;
    }
  },
  created() {
    if (this.isAuthenticated && this.enrolledCourses.length === 0) {
      this.$store.dispatch("getEnrolledCourses");
    } else if (!this.isAuthenticated && this.courseList.length === 0) {
      this.getCourseList();
    }
  },
  methods: {
    onClick(o) {
      if (o.id === "download" && this.awardDetail.download_url) {
        this.downloadAwards(this.awardDetail.download_url);
      } else if (o.id === "share") {
        this.shareAwards(this.awardDetail.share_url);
      }
    },
    isDisabled(o) {
      return o.id === "download" && this.awardDetail.download_url === null;
    },
    getCourseList() {
      let params = {
        pageNumber: 1,
        pageSize: 4,
        searchText: ""
      };
      this.$store
        .dispatch("getSearchList", params)
        .then(response => {
          this.courseList = response.results;
        })
        .catch(() => {
          this.courseList = [];
        });
    }
  }
};
</script>
<style lang="scss" scoped>
.detail__rightsection {
  width: 30%;
  .dr__batchcard {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    text-align: left;
    padding: 16px;
    gap: 8px;
    border-radius: 8px;
    background: $bg-default;
    .dr--top {
      @include horizontal-space-between;
      gap: 0 10px;
      .dr__batchcard__title {
        color: $brand-neutral-0;
        @include subtitle-regular;
        margin-bottom: 0;
      }
      .dr__batchcard__content {
        @include label-large($brand-neutral-0);
        text-align: left;
      }
    }
    .dr--bottom {
      @include horizontal-space-between;
      gap: 8px;
      .dr__batchcard--div {
        @include horizontal-space-between;
        gap: 8px;
        background: $brand-neutral-0;
        border-radius: 100px;
        padding: 8px 16px;
        cursor: pointer;
        &.disabled {
          background: $brand-neutral-75;
          cursor: not-allowed;
          .dr__batchcard--div__sec {
            span {
              color: $brand-neutral-300;
            }
          }
        }
        div {
          display: flex;
          gap: 8px;
          span {
            @include body-regular(#3160f8, 500);
          }
          .lxp-loader {
            min-height: 20px;
            display: flex;
            ::v-deep .spinner-border {
              width: 1.5rem;
              height: 1.5rem;
            }
          }
        }
        .dr__batchcard--div__sec--copied {
          color: $brand-success-400;
        }
      }
    }
  }
}
</style>
<style lang="scss">
.b-tooltip {
  .tooltip-inner {
    max-width: 290px;
    .cd__tooltip--inner {
      p {
        @include label-large(#fff !important, 400);
      }
    }
  }
}
</style>
